import React from 'react';

interface Props {
  icon: string;
  title: string;
  description: string | undefined;
}

const BenefitsItem: React.FC<Props> = ({ icon, title, description }: Props) => (
  <div className="shadow-sm h-100">
    <div className="pmp-card text-center bg-white">
      <div className="card-body">
        <img src={icon} alt={title} className="img-fluid w-50" />
        <p className="pt-4 subtitle-2">{title}</p>
        <p className="benefit-description">{description}</p>
      </div>
    </div>
  </div>
);

export default BenefitsItem;
