import React from 'react';
import { CardContentModel } from '../models';

const HowItWorksItem: React.FC<CardContentModel> = ({ icon, title }) => (
  <div>
    <div className="col-lg-6 col-md-8 col-6 p-3 mx-auto mb-2">
      <img src={icon} alt={title} className="step-image" />
    </div>
    <p className="col-md-10 col-sm-7 col-7 mx-auto subtitle-2">{title}</p>
  </div>
);

export default HowItWorksItem;
