import React from 'react';

interface Props {
  item: Item;
}

interface Item {
  label: string;
  href: string;
  role?: string;
}

const FooterItem: React.FC<Props> = ({ item }: Props) => (
  <p className="sub-text">
    <a
      className="footer-link"
      aria-label={item.label}
      target="_blank"
      href={item.href}
    >
      {item.label}
    </a>
  </p>
);

export default FooterItem;
