// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';

enum Size {
  // eslint-disable-next-line no-unused-vars
  Medium = 'medium'
}
interface Props {
  // eslint-disable-next-line react/require-default-props
  size?: Size;
  children: string;
}

const Icon: FC<Props> = ({ size = Size.Medium, children }: Props) => (
  <span className={`pmp-icon material-icons align-middle ${size}`}>
    {children}
  </span>
);

export default Icon;
