import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'gatsby';

// eslint-disable-next-line no-unused-vars
import { NavbarModel, NavbarStates, NavigationModel } from '../models';
import Icon from './icon';

class NavbarComponent extends React.Component<NavbarModel, NavbarStates> {
  constructor(props: NavbarModel) {
    super(props);
    this.state = {
      navbarOpen: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({
      navbarOpen: !prevState.navbarOpen,
    }));
  };

  render() {
    return (
      <div>
        <div className="d-block d-sm-none">
          <nav className="row navbar navbar-expand-lg bg--white d-flex justify-content-start">
            <button
              className="col-4 navbar-toggler text-left"
              type="button"
              onClick={this.toggleNavbar}
            >
              {this.state.navbarOpen ? <Icon>close</Icon> : <Icon>menu</Icon>}
            </button>
            <div className="col-4 text-center justify-content-center">
              <a className="navbar-brand d-block" href={this.props.logoLink}>
                <img
                  src="https://cdn-dev.print-marketplace.co.uk/frontend/pmp-logo-icon.svg"
                  alt="PMP logo"
                />
              </a>
            </div>
            <div className="col-4" />

            <div
              className={`collapse navbar-collapse ${
                this.state.navbarOpen ? 'show' : ''
              }`}
            >
              {this.props.mainMenu
                ? this.props.mainMenu.map((item, index) => (
                    <li className="nav-item" key={index}>
                      <Link
                        to={item.url}
                        className="nav-link color--accent text-uppercase"
                        activeClassName="font-weight-bold"
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))
                : ''}

              <ul className="navbar-nav mr-auto">
                {this.props.loginMenu.map(
                  (item: NavigationModel, index: number) => (
                    <li className="nav-item" key={index}>
                      <a
                        className="nav-link color--accent text-uppercase"
                        href={item.url}
                      >
                        {item.label}
                      </a>
                    </li>
                  )
                )}
              </ul>
            </div>
          </nav>
        </div>
        <div className="none-mobile d-none d-sm-block">
          <nav className="row p-5">
            <div className="col-md-5 col-lg-6 pb-4 pb-md-0 text-center text-md-left">
              <a
                className="logo-container d-inline-block"
                href={this.props.logoLink}
                aria-label="Go to main page"
              >
                <img
                  src={this.props.logo}
                  alt="logo"
                  className="main-logo"
                />
              </a>
            </div>
            <div className="col-md-7 col-lg-6 d-flex justify-content-end">
              <ul className="list-group list-group-horizontal list-unstyled mb-0 nav-menu">
                {this.props.mainMenu
                  ? this.props.mainMenu.map(
                      (item: NavigationModel, index: number) => (
                        <li className="mx-3" key={index}>
                          <Link
                            to={item.url}
                            className="btn btn-link text-white im"
                            activeClassName="current"
                          >
                            {item.label}
                          </Link>
                        </li>
                      )
                    )
                  : ''}

                <li>
                  <Dropdown className="login-drop-down">
                    <Dropdown.Toggle
                      variant="link text-white"
                      id="loginDropdown"
                    >
                      log in
                      <Icon>keyboard_arrow_down</Icon>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="text-uppercase mt-2">
                      {this.props.loginMenu.map(
                        (item: NavigationModel, index: number) => (
                          <Dropdown.Item
                            href={item.url}
                            key={index}
                            className="btn btn-link"
                          >
                            {item.label}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default NavbarComponent;
