import { FooterContent } from '../models';

export const footerContent: FooterContent[] = [
  {
    label: 'More to explore',
    items: [
      {
        label: 'Support',
        href: 'https://my.print-marketplace.co.uk/pages/support',
      },
      {
        label: 'Privacy policy',
        href: 'https://my.print-marketplace.co.uk/pages/privacy-policy',
      },
    ],
  },
  {
    label: 'About',
    items: [
      {
        label: 'Accessibility statement',
        href: 'https://my.print-marketplace.co.uk/pages/accessibility-statement',
      },
      {
        label: 'Modern slavery statement',
        href: 'https://my.print-marketplace.co.uk/pages/modern-slavery-statement',
      },
    ],
  },
];
