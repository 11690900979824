import React from 'react';

// eslint-disable-next-line no-unused-vars
import { HeroModel } from '../models';

class Hero extends React.Component<HeroModel> {
  // eslint-disable-next-line class-methods-use-this
  createdAccountPartial(enabled: boolean): JSX.Element {
    if (enabled) {
      return (
        <a
          href="https://printmarketplace.crowncommercial.gov.uk/auth/sign-up"
          aria-label="Create an account"
          target="_blank"
          className="btn bg--accent color--white mt-3 mr-md-5 mr-3"
        >
          <span className="align-middle">create account</span>
        </a>
      );
    }
    return <></>;
  }

  // eslint-disable-next-line class-methods-use-this
  enabledRegisterNowPartial(enabled: boolean): JSX.Element {
    if (enabled) {
      return (
        <a
          href="https://register.print-marketplace.co.uk"
          aria-label="Register"
          target="_blank"
          className="btn bg--accent color--white mt-3 mr-md-5 mr-3"
        >
          <span className="align-middle">register now</span>
        </a>
      );
    }

    return <></>;
  }

  // eslint-disable-next-line class-methods-use-this
  hasRequestedDemoPartial(enabled: boolean): JSX.Element {
    if (enabled) {
      return (
        <a
          id="reach-skip-nav"
          href="https://theapsgroup.typeform.com/to/u8NWFprV"
          target="_blank"
          aria-label="Request a demo"
          className="btn btn-outline-light mt-3 mr-md-5 mr-3"
        >
          <span className="align-middle">request a demo</span>
        </a>
      );
    }

    return <></>;
  }

  render() {
    const requestDemo: JSX.Element = this.hasRequestedDemoPartial(
      this.props.hasRequestADemo
    );
    const registerNow: JSX.Element = this.enabledRegisterNowPartial(
      this.props.hasRegisterNow
    );
    const createdAccount: JSX.Element = this.createdAccountPartial(
      this.props.hasCreateAccount
    );

    return (
      <section className="row mx-auto justify-content-center align-items-center text-center pt-md-5 pt-lg-5 pb-md-7 py-3 text-white">
        <div className="col-md-9 col-lg-7 mx-auto">
          <h1 className="text-white">{this.props.jumbotron}</h1>
          <p className="lead">{this.props.lead}</p>
          {requestDemo}
          {registerNow}
          {createdAccount}
        </div>
      </section>
    );
  }
}

export default Hero;
