import React from 'react';
// eslint-disable-next-line no-unused-vars
import { CardContentModel } from '../models';
import BenefitsItem from './benefits-item';

interface Props {
  benefits: any[];
}

const Benefits: React.FC<Props> = ({ benefits }: Props) => (
  <section className="row">
    {benefits.map((item: CardContentModel, index: number) => (
      <div className="col-md-4 mb-4" key={index}>
        <BenefitsItem
          icon={item.icon}
          title={item.title}
          description={item.description}
        />
      </div>
    ))}
  </section>
);

export default Benefits;
