import React from 'react';

import { IntroVideoModel } from '../models';

class IntroVideo extends React.Component<IntroVideoModel> {
  render() {
    return (
      <section className="embedded-video">
        <iframe
          id="video-label"
          aria-label="video about how it works"
          frameBorder="0"
          src={this.props.video}
        />
      </section>
    );
  }
}

export default IntroVideo;
