import React from 'react';
import PropTypes from 'prop-types';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => (
  <section>
    <main>{children}</main>
  </section>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
