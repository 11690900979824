/* eslint-disable no-unused-vars */
import React from 'react';

import FooterItem from './footer-item';
import { FooterContent } from '../models/footer-content';
import { FooterPartnerContent } from '../models/footer-partner-content';

interface Props {
  sections: any[];
  partners: any[];
  year: string;
}

const Footer: React.FC<Props> = ({ sections, partners, year }: Props) => (
  <footer className="pmp-footer">
    <div className="container text-center text-md-left pt-6 d-print-none">
      <div className="row pb-4 color--blue-dark">
        <div className="py-md-0 py-3 col-md-3">
          <p className="font-weight-bold">APS Group</p>
          <p className="sub-text">Chetham House</p>
          <p className="sub-text">Bird Hall Lane</p>
          <p className="sub-text">Cheadle Heath</p>
          <p className="sub-text">Cheshire, UK, SK3 0ZP</p>

          <div className="pt-4">
            <img
              src="https://cdn-dev.print-marketplace.co.uk/frontend/fsc-logo.svg"
              alt="FSC logo"
            />
            <img
              src="https://cdn-dev.print-marketplace.co.uk/frontend/pefc-logo.svg"
              alt="PEFC logo"
              className="pt-2 pl-4"
            />
          </div>
        </div>
        <div className="py-md-0 py-3 col-md-3">
          <p className="font-weight-bold">Contact us</p>
          <a
            href="mailto:support@print-marketplace.co.uk"
            className="footer-link"
            aria-label="Mail to support"
          >
            support@print-marketplace.co.uk
          </a>
          <p className="sub-text font-weight-bold">Monday - Friday</p>
          <p className="sub-text">8:30 AM - 5:30 PM</p>
          <p className="sub-text">+44 (0) 161 850 2831</p>
        </div>
        {sections.map((sectionItem: FooterContent, index: number) => (
          <div className="py-md-0 py-3 col-md-3" key={index}>
            <p className="subtitle-2 ">{sectionItem.label}</p>
            {sectionItem.items.map((item, i) => (
              <FooterItem key={i} item={item} />
            ))}
          </div>
        ))}
      </div>
    </div>

    <div className="container-fluid bg--blue-dark">
      <div className="container">
        <div className="row py-5 align-items-center">
          <div className="col-md-4 pb-5 pt-4 color--white text-md-left text-center">
            <small>&copy; {year} APS Group</small>
          </div>
          <div className="col-md-8">
            <div className="row">
              {partners.map((image: FooterPartnerContent, index: number) => (
                <div className="col d-flex align-content-center justify-content-center" key={index}>
                  <img
                    src={image.src}
                    alt={`logo ${image.name}`}
                    className={`img-fluid logo-${image.name}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
